.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.ant-spin {
  max-height: none !important;
}

.ant-pagination {
  display: flex;
  justify-content: right;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  .ant-pagination-item {
    margin: 0px 5px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 0px 10px;
    height: 30px;
    line-height: 30px;
    min-width: 30px;
    text-align: center;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
  }
}

.ant-pagination .ant-pagination-item-active {
  border: 1px solid #414de1 !important;
  color: #ffffff !important;
  background-color: #414de1;

  a {
    color: #fff !important;
  }
}

.table_box {
  position: relative;
  .ant-skeleton {
    position: absolute !important;
    margin: 20px 0px !important;
  }
  .ant-empty {
    position: absolute !important;
    padding: 0px 45%;
    margin: 4% 0px;
  }
  .ant-empty-image {
    height: 60px !important;
  }
}

.Toastify__toast {
  min-height: 1vw !important;
  background: rgba(255, 255, 255, 0.99) !important;
  color: rgb(0, 0, 0) !important;
  font-size: 15px;
  padding: 6px !important;
}

.Toastify__toast-body {
  padding: 4px !important;
}

.Toastify__toast-container {
  padding: 0 !important;
  width: 266px !important;
}
