// variable start here
$c_primary: #414de1;
$c_secondary: #00a1ff;
$c_heading: #1d1d1d;
$light: #def2ff;
$light_grey: #eef2f6;
$grey: #9aa4b2;
$c_black: #000;
$c_white: #ffffff;
$c_red: #d92d20;
$c_green: #079455;
$c_orange: #dc6803;
$f_body: "Graphik";
$f_goudy: "Goudy Bookletter 1911";
$transition: all 0.3s ease-in-out 0s;
// veriable end

// fonts
@import url("https://fonts.googleapis.com/css2?family=Goudy+Bookletter+1911&display=swap");

@font-face {
  font-family: "Graphik";
  src: url("../public/sitefont/Graphik-Black.woff2") format("woff2"),
    url("../public/sitefont/Graphik-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphik";
  src: url("../public/sitefont/Graphik-BlackItalic.woff2") format("woff2"),
    url("../public/sitefont/Graphik-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Graphik";
  src: url("../public/sitefont/Graphik-Bold.woff2") format("woff2"),
    url("../public/sitefont/Graphik-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphik";
  src: url("../public/sitefont/Graphik-LightItalic.woff2") format("woff2"),
    url("../public/sitefont/Graphik-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Graphik";
  src: url("../public/sitefont/Graphik-Light.woff2") format("woff2"),
    url("../public/sitefont/Graphik-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphik";
  src: url("../public/sitefont/Graphik-ExtralightItalic.woff2") format("woff2"),
    url("../public/sitefont/Graphik-ExtralightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Graphik";
  src: url("../public/sitefont/Graphik-Extralight.woff2") format("woff2"),
    url("../public/sitefont/Graphik-Extralight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphik";
  src: url("../public/sitefont/Graphik-BoldItalic.woff2") format("woff2"),
    url("../public/sitefont/Graphik-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Graphik";
  src: url("../public/sitefont/Graphik-Medium.woff2") format("woff2"),
    url("../public/sitefont/Graphik-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphik";
  src: url("../public/sitefont/Graphik-Thin.woff2") format("woff2"),
    url("../public/sitefont/Graphik-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphik Super";
  src: url("../public/sitefont/Graphik-Super.woff2") format("woff2"),
    url("../public/sitefont/Graphik-Super.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphik";
  src: url("../public/sitefont/Graphik-MediumItalic.woff2") format("woff2"),
    url("../public/sitefont/Graphik-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Graphik";
  src: url("../public/sitefont/Graphik-RegularItalic.woff2") format("woff2"),
    url("../public/sitefont/Graphik-RegularItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Graphik";
  src: url("../public/sitefont/Graphik-Semibold.woff2") format("woff2"),
    url("../public/sitefont/Graphik-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphik";
  src: url("../public/sitefont/Graphik-Regular.woff2") format("woff2"),
    url("../public/sitefont/Graphik-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphik Super";
  src: url("../public/sitefont/Graphik-SuperItalic.woff2") format("woff2"),
    url("../public/sitefont/Graphik-SuperItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Graphik";
  src: url("../public/sitefont/Graphik-SemiboldItalic.woff2") format("woff2"),
    url("../public/sitefont/Graphik-SemiboldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Graphik";
  src: url("../public/sitefont/Graphik-ThinItalic.woff2") format("woff2"),
    url("../public/sitefont/Graphik-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

.goudy {
  font-family: $f_goudy;
}

// reset css start here

* {
  outline: none !important;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  background-color: #f6fcff;
  color: $c_heading;
  font-family: $f_body;
}

header {
  position: fixed;
  width: 100%;
  top: 0;
}

a,
a:hover {
  //   color: $c_text;
  transition: $transition;
}

textarea {
  font-family: $f_body;
}

b,
strong {
  font-weight: 600;
}

a {
  text-decoration: none;
  font-size: 16px;
}

li {
  list-style: none;
}

:is(h1, h2, h3, h4, h5, h6) {
  color: $c_heading;
  margin-bottom: 0;
  font-family: $f_body;
}

p {
  margin-bottom: 0;
}

img {
  position: unset !important;
}

figure {
  line-height: 0;
}

button,
select {
  cursor: pointer;
}

h1,
.hd_1 :where(h1, h2, h3, h4, h5, h6),
.ab_cnt.hd_1 h1 {
  font-size: 56px;
  font-weight: 700;
  line-height: 1.2;

  @media screen and (max-width: 1399.98px) {
    font-size: 45px;
  }

  @media screen and (max-width: 1199.98px) {
    font-size: 42px;
  }

  @media screen and (max-width: 767.98px) {
    font-size: 36px;
  }
}

h2,
.hd_2 :where(h1, h2, h3, h4, h5, h6) {
  font-size: 36px;
  font-weight: 700;
  line-height: 1.2;

  @media screen and (max-width: 1399.98px) {
    font-size: 36px;
  }

  @media screen and (max-width: 1199.98px) {
    font-size: 32px;
  }

  @media screen and (max-width: 767.98px) {
    font-size: 28px;
  }
}

h3,
.hd_3 :where(h1, h2, h3, h4, h5, h6) {
  font-size: 40px;
  font-weight: 700;
  line-height: 1.2;

  @media screen and (max-width: 1399.98px) {
    font-size: 36px;
  }

  @media screen and (max-width: 1199.98px) {
    font-size: 32px;
  }

  @media screen and (max-width: 991.98px) {
    font-size: 30px;
  }

  @media screen and (max-width: 991.98px) {
    font-size: 25px;
  }
}

h4,
.hd_4 :where(h1, h2, h3, h4, h5, h6) {
  font-size: 34px;
  font-weight: 700;
  line-height: 1.2;
}

h5,
.hd_5 :where(h1, h2, h3, h4, h5, h6),
.m_hd h3 {
  font-size: 30px;
  font-weight: 700;
  line-height: 1.2;

  @media screen and (max-width: 1399.98px) {
    font-size: 28px;
  }
}

h6,
.hd_6 :where(h1, h2, h3, h4, h5, h6) {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;

  @media screen and (max-width: 1199.98px) {
    font-size: 22px;
  }
}

ul {
  padding: 0;
}

body,
p,
li {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  //   color: $c_text;
  font-family: $f_body;

  @media screen and (max-width: 1199.98px) {
    font-size: 16px;
  }
}

.conta_iner {
  max-width: 1200px;
  margin: 0 auto;

  @media screen and (max-width: 1400px) {
    width: 90%;
  }
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}

.btn_primary {
  border-radius: 1.25rem;
  line-height: normal;
  background: var(
    --gradient,
    linear-gradient(89deg, #414de1 0%, #25c4f6 100%, #02bbf5 100%)
  );
  color: $c_white;
  border: none;
  padding: 20px 40px;
  font-size: 1rem;
  position: relative;
  overflow: hidden;
  font-family: $f_body;

  &::before {
    content: "";
    top: 0;
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 1;
    animation: slide 2s infinite;
    background: linear-gradient(
      to right,
      rgba(64, 235, 241, 0) 0%,
      rgba(255, 254, 253, 0.56) 50%,
      rgba(255, 254, 253, 0.28) 69%,
      rgba(64, 235, 241, 0) 100%
    );
  }

  &:hover {
    color: $c_heading;
    background: $c_white;
  }
}

@keyframes slide {
  0% {
    left: -100%;
  }

  100% {
    left: 100%;
  }
}

.d_flx {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.title_bar {
  gap: 15px;
}

.truncate {
  width: 170px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.align_top {
  align-items: unset;
}

.bg_dark {
  background-color: $c_heading;
}

.clr_theme {
  color: $c_primary;
  font-weight: 600;
}

.secondary_btn {
  border-radius: 1.25rem;
  background: var(--primary, #414de1);
  color: #fff;
  padding: 12px 30px;
  border: none;
  font-size: 16px;
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    top: 0;
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 1;
    animation: slide 2s infinite;
    background: linear-gradient(
      to right,
      rgba(64, 235, 241, 0) 0%,
      rgba(255, 254, 253, 0.56) 50%,
      rgba(255, 254, 253, 0.28) 69%,
      rgba(64, 235, 241, 0) 100%
    );
  }

  &:hover {
    background-color: $c_black;
    color: $c_white;
  }
}

.wt_reg {
  font-weight: 400;
}

.al_sc {
  padding: 60px 0;
}

.scrolled {
  background-color: $c_heading;
  z-index: 1024;
}

.input_box select {
  width: 100%;
  border-radius: 0.5rem;
  border: 1px solid #aaaaaaaa;
  background: #ffffff;
  padding: 10px 40px 10px 10px;
  font-size: 16px;
  color: #9aa4b2;
  font-weight: 400;

  option {
    cursor: pointer;
  }
}

.ut_spc,
.u_spc {
  padding-top: 80px;

  @media screen and (max-width: 1199.98px) {
    padding-top: 60px;
  }

  @media screen and (max-width: 767.98px) {
    padding-top: 50px;
  }
}

.ub_spc,
.u_spc {
  padding-bottom: 80px;

  @media screen and (max-width: 1199.98px) {
    padding-bottom: 60px;
  }

  @media screen and (max-width: 767.98px) {
    padding-bottom: 50px;
  }
}

.uht_spc,
.uh_spc {
  padding-top: 30px;

  @media screen and (max-width: 1199.98px) {
    padding-top: 20px;
  }
}

.mrg_tbbt {
  margin: 20px 0;
}

.uhb_spc,
.uh_spc {
  padding-bottom: 30px;

  @media screen and (max-width: 1199.98px) {
    padding-bottom: 20px;
  }
}

.clr_white :where(h1, h2, h3, h4, h5, h6, p, a, li) {
  color: $c_white;
}

.line {
  border: none;
  border-bottom: 1px solid #fff;
  margin: 10px 0;
}

.w_48 {
  width: 48%;

  @media screen and (max-width: 992px) {
    width: 100%;
  }
}

.gry_txt {
  color: $grey;
  margin: 15px 0;
}

.text_center {
  text-align: center;
}

.max_60 {
  max-width: 60%;
  margin: 0 auto;
}

.wd_60 {
  max-width: 60%;
}

.lt_brdr h3 {
  font-size: 16px;
  max-width: 60%;
  border-left: 3px solid $c_primary;
  padding-left: 14px;
}

.mtb_60 {
  margin: 60px 0;
}

.mb_40 {
  margin-bottom: 40px;
}

.switch_btn {
  background: none;
  border: 1px solid$c_primary;
  color: $c_primary;
  padding: 5px 10px;
  border-radius: 5px;

  &:hover {
    background: $c_primary;
    color: $c_white;
  }
}

.input_checkbox {
  label {
    font-size: 16px;
    cursor: pointer;
  }
}

.login_btn {
  display: block;
  margin: 30px 0;
  background: $c_primary;
  color: $c_white;
  overflow: hidden;
  border: none;
  width: 100%;
  height: 45px;
  font-size: 16px;
  border-radius: 10px;
  position: relative;

  &::before {
    content: "";
    top: 0;
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 1;
    animation: slide 2s infinite;
    background: linear-gradient(
      to right,
      rgba(64, 235, 241, 0) 0%,
      rgba(255, 254, 253, 0.56) 50%,
      rgba(255, 254, 253, 0.28) 69%,
      rgba(64, 235, 241, 0) 100%
    );
  }
}

.google_btn {
  background: none;
  min-width: 200px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 17px;
  font-size: 16px;
  border: 1px solid $c_primary;
  color: $c_primary;
}

.mr_auto_50 {
  margin: 50px auto 50px;
}

.back_arrow {
  background: $c_primary;
  width: 30px;
  height: 30px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: -60px;

  i {
    color: $c_white;
  }
}

.m_0 {
  margin: 0;
}

.flx_input input {
  padding-right: 5px;
}

.flx_input .input_box {
  width: 48%;
  margin: 15px 0;
}

.mt_0 {
  margin-top: 0;
}

.pd_input input {
  padding: 18px;
}

.save_btn {
  border: none;
  background: $c_primary;
  padding: 12px 25px;
  color: $c_white;
  border-radius: 5px;
  font-size: 16px;
  border: 1px solid $c_primary;

  &:hover {
    color: $c_primary;
    background: transparent;
  }
}

input:focus {
  border-color: $c_heading;
}

/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $c_primary;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $c_primary;
}

.bg_bx {
  background: #fff;
  border-radius: 15px;
  padding: 15px 15px 15px 15px;
  min-height: calc(100vh - 200px);
}

.green_text {
  color: $c_green;
  font-weight: 500;
}

.blue_text {
  color: $c_primary;
  font-weight: 500;
}

.red_text {
  color: $c_red;
  font-weight: 500;
}

.orange_text {
  color: $c_orange;
  font-weight: 500;
}

// reset css end here

// header css start
.main_header {
  background: $c_white;
  box-shadow: 0px 8px 16px -6px rgba(45, 115, 227, 0.08),
    0px 6px 8px -6px rgba(45, 115, 227, 0.12);
  height: 56px;
  position: fixed;
  width: 100%;
  top: 0;
  padding: 8px 30px;
  z-index: 2;

  .top_title {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 30px;

    li {
      position: relative;

      .usr_img {
        width: 40px;
        height: 40px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }
    }
  }
}

//   header css end
.ReactModal__Content.ReactModal__Content--after-open {
  border-radius: 1rem !important;
  background: var(--white, #fff) !important;
  box-shadow: 0px 10px 32px -4px rgba(45, 115, 227, 0.1),
    0px 6px 14px -6px rgba(45, 115, 227, 0.12) !important;
  border: none !important;
  max-width: 600px;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background: #000000a6 !important;
  z-index: 5;
}

// sidebar css start
.sidebar {
  position: fixed;
  min-width: max-content;
  top: 0;
  height: 100%;
  // border-right: 1px solid var(--border, #eef2f6);
  background: var(--white, #fff);
  box-shadow: 0px 8px 16px -6px rgba(45, 115, 227, 0.08),
    0px 6px 8px -6px rgba(45, 115, 227, 0.12);
  z-index: 3;
  transition: 0.2s all;
  @media screen and (max-width: 1200px) {
    left: -240px;
  }

  .logo_sidebar {
    padding: 20px 0;
    text-align: center;
  }

  ul {
    li {
      padding: 5px 0;
      text-align: center;

      img {
        filter: brightness(0);
        opacity: 0.6;
        max-width: 20px;
        height: 20px;
        object-fit: contain;
      }

      a {
        display: flex;
        align-items: center;
        gap: 15px;
        width: 100%;
        color: $c_heading;
        padding: 10px 20px;
        font-size: 15px;
        font-weight: 500;

        .st_clp {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
        }

        &:hover {
          color: $c_primary;

          img {
            filter: none;
            opacity: 1;
          }
        }
      }

      .sub_menu {
        padding-left: 30px;

        li {
          a {
            padding: 5px;
          }
        }
      }
    }

    .active {
      background: $c_primary;

      a {
        color: $c_white;
      }

      img {
        filter: brightness(0) invert(1);
        opacity: 1;
      }

      &:hover {
        a {
          color: $c_white;
        }

        img {
          filter: brightness(0) invert(1);
          opacity: 1;
        }
      }
    }
  }
}

// sidebar css end

.main_area {
  background: #f6fcff;
  min-height: calc(100vh - 60px);
  padding: 60px 15px 10px 250px;
  @media screen and (max-width: 1200px) {
    padding: 60px 15px 10px 15px;
  }
}

.card_bx {
  width: 24%;
  min-height: 172px;
  flex-wrap: nowrap;
  gap: 20px;
  justify-content: flex-start;
  @media screen and (max-width: 992px) {
    width: 49%;
    min-height: 120px;
  }

  @media screen and (max-width: 575px) {
    width: 100%;
    justify-content: flex-start;
    gap: 20px;
    min-height: 110px;
  }
}

.shd_bx {
  background: #fff;
  border: 0.5px solid #ededed;
  border-radius: 0.875rem;
  box-shadow: 0 4px 4px 0 rgb(0 0 0 / 3%);
  margin: 13px 0;
  padding: 20px;
  @media screen and (max-width: 1400px) {
    padding: 10px;
  }

  p {
    @media screen and (max-width: 1400px) {
      min-width: 63%;
    }
  }
}

.chat_bot {
  min-height: calc(110vh - 200px);
  // min-height: 300px;

  h3 {
    font-size: 18px;
    font-weight: 500;
  }

  p {
    font-size: 16px;
  }
}

.table_box {
  margin-top: 30px;

  table {
    width: 100%;

    th {
      font-size: 16px;
      font-weight: 500;
      white-space: nowrap;
      min-width: 100px;

      &:nth-child(1) {
        text-align: left;
        min-width: auto;
      }
    }

    td {
      color: #232323;
      max-width: 300px;
      font-size: 14px;
      padding: 12px 10px;
      text-align: center;

      &:nth-child(1) {
        text-align: left;
        padding-left: 0;
      }

      p {
        max-width: 400px;
        text-align: center;
        margin: 0 auto;
      }

      .usr_img img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
      }

      .rating_star i {
        color: $c_primary;
        font-size: 12px;
      }

      [type="checkbox"] {
        cursor: pointer;
      }
      .description_bx {
        min-width: 250px;
      }
    }

    .tbl_icon {
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: center;

      li {
        button,
        a {
          background: #68a6a02e;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: 0.2s all;

          img {
            min-width: 15px;
          }
        }

        &:nth-child(3) {
          button {
            background: #ec666426;
          }
        }

        &:hover {
          button {
            transform: scale(1.1);
            transition: 0.2s all;
          }
        }
      }
    }
  }
}

.lg_frm {
  .input_box {
    .eye {
      top: 20px;
    }
  }
}

.input_box {
  position: relative;
  max-width: 300px;

  .sms {
    position: absolute;
    left: 15px;
    top: 18px;
  }

  .eye {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }

  .edit_eye {
    position: absolute;
    right: 20px;
    top: 18px;
    cursor: pointer;
  }

  input {
    width: 100%;
    border-radius: 0.5rem;
    border: 1px solid #aaaaaaaa;
    background: $c_white;
    padding: 10px 40px 10px 10px;
    font-size: 16px;
    color: $c_heading;
    font-weight: 400;

    &::placeholder {
      color: $grey;
      font-weight: 200;
    }

    &:focus {
      border-color: $c_heading;
    }
  }
}

.jst_right {
  justify-content: right;
}

.add_btn {
  background: none;
  border: 1px solid $c_primary;
  border-radius: 5px;
  color: $c_primary;
  padding: 10px 15px;

  &:hover {
    background-color: $c_primary;
    color: $c_white;
  }
}

.view_btn {
  background: $c_primary;
  border: 1px solid $c_primary;
  border-radius: 5px;
  color: $c_white;
  padding: 10px 15px;

  &:hover {
    background: none;
    color: $c_primary;
  }
}

.srch_add {
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
}

// toggle css
.switch {
  position: relative;
  display: inline-block;
  width: 46px;
  height: 22px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #414de1;
}

input:checked + .slider:before {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

//  toggle css end
.prf_head {
  margin: 15px 0;
}

h1 {
  font-size: 18px;
  font-weight: 500;

  p {
    font-size: 16px;
  }
}

.bot_img {
  width: 50px;
  height: 50px;
  margin: 0 auto;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.fld_lhs {
  label {
    font-size: 16px;
  }

  .input_box {
    margin: 15px 0;
    max-width: 100%;
  }

  .save_btn {
    margin-top: 20px;
  }
}

.fld_rhs {
  margin-bottom: 50px;

  figure {
    background: #414de10d;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    // margin: 0 auto;

    .input_box {
      position: absolute;
      left: 0;
      top: 0;
      width: 200px;
      height: 200px;
      margin: 0;

      input {
        font-size: 0;
        height: 100%;
        opacity: 0;
        cursor: pointer;
        border-radius: 50%;
      }
    }
  }
}

// login page css statr here
.login_page {
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;

  &::before {
    background: $c_primary;
    filter: blur(250px);
    content: "";
    position: absolute;
    top: 0;
    width: 300px;
    height: 300px;
    left: 0;
    opacity: 0.5;
  }

  &::after {
    background: linear-gradient(89deg, #414de1 0%, #25c4f6 100%, #02bbf5 100%);
    filter: blur(250px);
    content: "";
    position: absolute;
    bottom: 0;
    width: 300px;
    height: 300px;
    right: 0;
    opacity: 0.5;
  }

  .login_bx {
    position: relative;
    width: 600px;
    border-radius: 1rem;
    background: $c_white;
    overflow: hidden;
    padding: 80px;
    box-shadow: 0px 10px 32px -4px rgba(45, 115, 227, 0.1),
      0px 6px 14px -6px rgba(45, 115, 227, 0.12);

    &::before {
      content: "";
      background-color: #092c4e;
      position: absolute;
      top: -50px;
      right: -30px;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      @media screen and (max-width: 575px) {
        display: none;
      }
    }

    &::after {
      content: "";
      background-color: #ff6671;
      position: absolute;
      bottom: -50px;
      right: 20px;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      @media screen and (max-width: 575px) {
        display: none;
      }
    }

    .lg_frm {
      &::after {
        content: "";
        background: #ff000000;
        position: absolute;
        width: 60px;
        height: 60px;
        left: -50px;
        border-radius: 50%;
        border: 20px solid #ffcd01;
        bottom: 30%;
        @media screen and (max-width: 575px) {
          display: none;
        }
      }
    }

    .form_head {
      margin-bottom: 50px;
      position: relative;

      figure {
        margin-bottom: 30px;
      }

      h1 {
        font-size: 18px;
        font-weight: 400;
      }
    }

    .input_box {
      max-width: 100%;
      margin: 15px 0;
      position: relative;

      input {
        padding: 18px 50px;
      }
    }

    a:hover {
      color: #404ee1;
    }
    @media screen and (max-width: 575px) {
      background: none;
      box-shadow: none;
      padding: 20px;
    }
  }
}

.an_aacount {
  text-align: center;

  a {
    color: $c_primary;
  }
}

// login page css end here
.w_49 {
  width: 49%;
  @media screen and (max-width: 575px) {
    width: 100%;
  }
}

.mb_50 {
  margin-bottom: 50px;
}

.perm_module {
  display: flex;
}

.add_form {
  align-items: unset;
  margin: 20px 0 30px;

  .input_box {
    max-width: 100%;
    margin: 10px 0;

    input {
      padding: 15px 10px;
    }
  }

  label {
    font-size: 16px;
  }

  select {
    padding: 15px 10px;
  }

  .ad_bnt {
    font-size: 14px;
    display: block;
    text-align: right;
    color: $c_primary;
    font-weight: 500;
    cursor: pointer;
  }
}

.w_100 {
  width: 100%;
}

.text_area {
  width: 100%;
  border-radius: 0.5rem;
  border: 1px solid #aaaaaaaa;
  background: #ffffff;
  padding: 10px 40px 10px 10px;
  font-size: 16px;
  color: #9aa4b2;
  font-weight: 400;
  resize: none;
  min-height: 85px;
}

.add_profile {
  width: 150px;
  height: 150px;
  position: relative;
  margin-bottom: 60px;

  p {
    font-size: 15px;
    text-align: center;
  }

  figure {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 10px;
    border: 1px solid #ecf6fd;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    input {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      font-size: 0;
      opacity: 0;
      cursor: pointer;
    }
  }
}

.details_page {
  display: flex;
  gap: 30px;
  margin-top: 30px;
  flex-wrap: wrap;

  .details_lhs {
    width: 28%;

    figure {
      width: 230px;
      height: 230px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    @media screen and (max-width: 992px) {
      width: 100%;
    }
  }

  .details_rhs {
    width: 68%;

    i {
      font-size: 16px;
      margin-right: 10px;
    }

    h2 {
      font-size: 30px;
      margin-bottom: 15px;
      display: flex;
      align-items: center;
    }

    h3,
    h4,
    h5,
    h6 {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 15px;
    }
    @media screen and (max-width: 992px) {
      width: 100%;
    }
  }
}

.ReactModal__Overlay--after-open {
  background: #00000096 !important;
  z-index: 5;
}

.subscription_modal {
  .modal_head {
    margin-bottom: 15px;

    i {
      padding: 10px;
      cursor: pointer;
      font-size: 17px;
      background: $c_primary;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      color: $c_white;
    }

    h2 {
      font-size: 20px;
      font-weight: 500;
    }
  }

  .subs_one {
    width: 100%;
    border: 1px solid #eef2f6;
    padding: 15px;
    min-width: 500px;
    @media screen and (max-width: 575px) {
      min-width: 400px;
    }
    @media screen and (max-width: 420px) {
      min-width: 300px;
    }

    p {
      font-size: 18px;
      color: $grey;
      font-weight: 300;
    }

    .subs_head {
      margin-bottom: 15px;

      h3,
      h4 {
        font-size: 22px;
        font-weight: 600;
      }
    }

    ul {
      padding: 10px 0;

      li {
        font-size: 18px;
        color: $grey;
        padding: 5px 0;
      }
    }
  }
}

.add_notification {
  min-width: 500px;

  .field_slct {
    margin-bottom: 15px;

    label {
      font-size: 16px;
    }

    .input_box {
      max-width: 100%;
      margin: 10px 0;
    }
  }

  textarea {
    width: 100%;
    border-radius: 0.5rem;
    border: 1px solid #aaaaaaaa;
    background: #ffffff;
    padding: 10px 40px 10px 10px;
    font-size: 16px;
    color: #9aa4b2;
    font-weight: 400;
    resize: none;
    margin-top: 10px;
  }
}

.grph_both {
  .graph_chart {
    position: relative;
    width: 49.3% !important;
    height: auto !important;
    background: #fff;
    border: 0.5px solid #eef2f6;
    border-radius: 0.875rem;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.04);
    margin: 13px 0;
    padding: 20px;

    h4 {
      font-size: 12px;
      text-align: center;
      font-weight: 500;
    }

    .input_box {
      width: 130px;
      margin-left: auto;

      select {
        padding: 6px 14px 6px 4px;
        font-size: 13px;
      }
    }
    @media screen and (max-width: 575px) {
      width: 100% !important;
    }
  }
}

.subs_history {
  margin-top: 50px;

  .react-tabs__tab-list {
    border: 1px solid #def2ff;
    border-radius: 10px;
    padding: 5px 5px 7px 5px;
    display: flex;
    white-space: nowrap;
    overflow: auto;
    .react-tabs__tab {
      border: none;
      font-size: 16px;
      background: none;
      color: $grey;
    }

    .react-tabs__tab--selected {
      background: $c_primary;
      color: $c_white;
      border-radius: 5px;
    }
  }
}

.csat_toggle {
  max-width: 40%;
  margin: 30px 0 10px;
}

.upload_bot {
  display: flex;
  align-items: center;
  gap: 3%;
  margin-top: 20px;
  flex-wrap: wrap;

  li {
    width: calc(20% - 40px);
    border: 1px solid $light;
    position: relative;
    height: 150px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px 0;

    &:nth-child(1) {
      cursor: pointer;

      i {
        font-size: 40px;
        color: $grey;
      }

      input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        font-size: 0;
        opacity: 0;
        cursor: pointer;
      }

      &:hover {
        border: 1px solid $c_heading;
      }
    }

    figure {
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: top;
      }
    }

    .fa-xmark {
      position: absolute;
      top: -10px;
      right: -10px;
      background: #414de1;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-size: 12px;
      color: #fff;
      cursor: pointer;
    }
  }
  @media screen and (max-width: 1200px) {
    gap: 20px;
    li {
      width: calc(20% - 17px);
      @media screen and (max-width: 992px) {
        width: calc(25% - 17px);
      }
      @media screen and (max-width: 767px) {
        width: calc(33% - 17px);
      }
      @media screen and (max-width: 575px) {
        width: 47%;
      }
      @media screen and (max-width: 480px) {
        height: 120px;
        width: 45%;
      }
    }
  }
}
.rating_star {
  white-space: nowrap;
}
.side_chat {
  position: fixed;
  background: white;
  right: 00;
  top: 0;
  z-index: 2;
  height: 100vh;
  border-left: 1px solid $light;
  max-width: 600px;
  right: -600px;
  transition: 0.5s all;

  .title_bar {
    background: #414de10d;
    padding: 20px;

    h2 {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .person_chat {
    padding: 20px;
    height: calc(100vh - 77px);
    overflow: auto;

    .rhs_chat {
      max-width: 60%;
      margin: 40px 0 40px auto;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      gap: 10px;

      h3 {
        font-size: 14px;
        width: fit-content;
      }

      figure {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .chat_right {
        padding: 13px 30px;
        background: $c_primary;
        border-radius: 55px;
        width: fit-content;

        p {
          color: $c_white;
        }
      }
    }

    .lhs_chat {
      max-width: 60%;
      margin: 40px 0;
      gap: 10px;

      h3 {
        font-size: 14px;
        width: fit-content;
      }

      figure {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        margin-bottom: 8px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .chat_left {
        padding: 13px 30px;
        margin-top: 10px;
        background: $light;
        border-radius: 55px;
        width: fit-content;
      }
    }
  }
}

.side_chat.active {
  right: 0;
  transition: 0.5s all;
}

.chng_pswd {
  padding: 20px 0;
  border-bottom: 0.5px solid #eef2f6;
  margin-top: 20px;
  cursor: pointer;

  h2 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
    color: $grey;
  }
}

// change password modal
.change_password {
  p {
    font-size: 14px;
    max-width: 65%;
    margin: 10px 0;
    color: $grey;
  }

  .password_head {
    border-bottom: 0.5px solid #eef2f6;
    padding-bottom: 20px;
    margin-bottom: 15px;

    h2 {
      font-size: 20px;
      font-weight: 500;
    }
  }

  label {
    font-size: 16px;
  }

  .input_box {
    max-width: 100%;
    margin: 15px 0;

    input {
      padding: 18px;
    }
  }
}

.btn_grp {
  display: flex;
  align-items: center;
  justify-content: right;
  margin-top: 50px;
  gap: 15px;

  .cancel_btn {
    border: 1px solid #414de1;
    padding: 12px 25px;
    font-size: 16px;
    color: #414de1;
    border-radius: 5px;
    background: none;

    &:hover {
      background: $c_primary;
      color: $c_white;
    }
  }
}

.fld_rhs {
  figure {
    img {
      width: 200px;
      height: 200px;
      object-fit: cover;
      border-radius: 50%;
    }
  }
}

.otp-input {
  margin: 16px 0;

  div {
    justify-content: center;

    input {
      width: 40px !important;
      height: 40px;
      margin: 0 10px;
      border-radius: 5px;
      border: 0.5px solid #dddddd;
    }
  }
}

.verify_email {
  h2 {
    font-size: 24px;
    font-weight: 400;
    text-align: center;
    border-bottom: 1px solid $light;
    padding-bottom: 20px;
    margin: 0 20px;
  }

  p {
    max-width: 90%;
    margin: 0 auto;
    text-align: center;
  }
}
.email_icon {
  border: 1px solid $c_primary;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 auto 20px;
}

.perm_module {
  justify-content: space-between;

  tbody:first-child {
    th,
    td {
      padding: 10px;
    }
  }

  tbody:last-child {
    th {
      min-width: 100px;
    }

    th,
    td {
      text-align: center;
      padding: 10px;
    }
  }
}

.custom_date {
  display: flex;
  align-items: flex-end;
  gap: 5px;
  background-color: $c_white;
  padding: 6px;

  label {
    display: block;
  }

  input {
    width: 100%;
    border-radius: 0.5rem;
    border: 1px solid rgba(170, 170, 170, 0.6666666667);
    background: #ffffff;
    padding: 3px 3px 3px 3px;
    font-size: 12px;
    color: #9aa4b2;
    font-weight: 400;
    cursor: pointer;
  }

  button {
    background-color: $c_primary;
    border: none;
    color: $c_white;
    padding: 2px 6px 7px;
    border-radius: 5px;
  }
}

.hdr_logout {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 7px 20px;
  color: #1d1d1d;
  font-size: 18px;
  cursor: pointer;
}

.shd_bx {
  .table_box {
    position: relative;
    overflow-x: auto;
    min-height: 350px;

    tbody {
      text-align: center;

      .No_data {
        position: absolute;
        left: 0;
        right: 0;
        top: 60px;

        i {
          display: block;
          font-size: 20px;
          margin-top: 6px;
        }
      }
    }
  }
}
.sidebar.active {
  left: 0;
  transition: 0.2s all;
}

.toggle_sidebar.active {
  left: 230px;
  transition: 0.2s all;
}
.toggle_sidebar {
  position: absolute;
  left: 15px;
  top: 11px;
  transition: 0.2s all;
  cursor: pointer;
  background: white;
  padding: 1px 5px;
  z-index: 9999;

  span {
    display: block;
    width: 30px;
    height: 3px;
    background: #1d1d1d;
    margin: 6px 0;
    border-radius: 35px;
  }
}

.ant-dropdown-menu-title-content {
  p {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 6px 8px;
    color: $c_heading;
    font-size: 18px;

    &:hover {
      background: #414de112;
    }
  }
}

.ant-dropdown-menu {
  .noti_fication {
    justify-content: space-between;
    min-width: 450px;
    padding: 0 15px;
    display: block;

    .ant-empty .ant-empty-image {
      height: 70px !important;
    }

    li {
      width: 100%;
      border-bottom: 2px dashed $light;
      padding: 15px 0;

      .noti_cnt {
        display: flex;
        align-items: center;
        gap: 10px;
      }

      figure {
        width: 29px;
        height: 29px;
        background: $light_grey;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }

      p {
        font-size: 16px;
      }

      span {
        font-size: 13px;
        color: $c_primary;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}
.ant-dropdown-menu-item:hover {
  background-color: rgb(255 255 255) !important;
}

.ant-drawer-body {
  padding: 0 !important;
  .person_chat {
    padding: 20px;
    height: calc(100vh - 77px);
    overflow: auto;

    .assigned_message {
      position: relative;
      p {
        font-size: 16px;
        width: -moz-fit-content;
        width: fit-content;
        padding: 15px;
        background-color: #fff;
        margin: 0 auto;
        z-index: 1;
        position: relative;
      }
    }
    .assigned_message::before {
      content: "";
      background: #414de1;
      height: 1px;
      width: 100%;
      position: absolute;
      top: 23px;
      left: 0;
    }

    .rhs_chat {
      max-width: 60%;
      margin: 40px 0 40px auto;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      gap: 10px;

      h3 {
        font-size: 14px;
        width: fit-content;
      }

      figure {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .chat_right {
        padding: 13px 30px;
        background: $c_primary;
        border-radius: 55px;
        width: fit-content;

        p {
          color: $c_white;
        }
      }
    }

    .lhs_chat {
      max-width: 60%;
      margin: 40px 0;
      gap: 10px;

      h3 {
        font-size: 14px;
        width: fit-content;
      }

      figure {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        margin-bottom: 8px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .chat_left {
        padding: 13px 30px;
        margin-top: 10px;
        background: $light;
        border-radius: 55px;
        width: fit-content;
      }
      .options_box {
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .welcome_msg {
        background-color: #fff8e8;
        border: 1px solid #ffe9bd;
        box-sizing: border-box;
        border-radius: 0px 5px 5px 5px;
      }
    }
  }
}
